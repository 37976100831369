import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[`blog`, `software`, `improvement`, `habits`, `mastery`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Thanks for stopping by
          </h2>

          <hr />
          {/* <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Large imagery is at the heart of this theme</figcaption>
          </figure> */}
          {/* <h3 id="dynamic-styles">Dynamic styles</h3> */}
          <p>Hey there. I'm Eddie.</p>
          <p>
            You're probably not here to be gobsmacked by a scroll of my industry
            experience. Instead, I'll share some things I'm really passionate
            about.
          </p>
          <p>
            I have enduring love for building mastery through habits. I love
            manifesting the aggregation of marginal gains. I get excited about
            putting software together. I am thrilled by man's constant search
            for meaning. I get up in the mornings to better connect with my
            fellow people on an insightful and emotional level.
          </p>

          <h4 id="dynamic-styles">Who I am</h4>
          <p>
            I build by typing and create things that computers can understand. I
            build relationships by talking about these things in ways that
            humans can understand.
          </p>

          <h4 id="dynamic-styles">What you'll find here</h4>
          <p>
            I'm here to share my pursuit of constant improvement, so you can
            join me on this path. I'm here to talk about technical things that
            tickle my brain and build better, collective understanding. Above
            all, I'm here to build empathy.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
